<template lang="pug">
    td(v-bind="attributes")
        span(v-if="isText", v-html="content")
        component(:is="item.component", :contents="item.contents", v-for="item, index in mappedComponents", :key="`${item.type}-${index}`", v-else)
</template>

<script>
import { tableComponents as components, tableDictionary, props } from '@/mixins/component'

export default {
    name: "component-tabela-coluna",
    props,
    components,
    computed: {
        mappedComponents() {
            if (this.isText)
                return []
            return this.content.map(item => {
                return { component: this.$options.components[tableDictionary[item.type]], contents: item, type: item.type }
            })
        },
        attributes() {
            let attr = Object.assign({}, this.contents)
            delete attr.type
            delete attr.content
            return attr
        },
        content() {
            return this.contents.content
        },
        isText() {
            return typeof this.content === 'string'
        }
    }
}
</script>

<style lang="stylus" scoped src="./TabelaColuna.styl"></style>